import React, {useState, Fragment} from 'react'
import {Col, Row, Pagination, Card} from 'react-bootstrap'
import {Subtitle,Loader} from './../General.js'
import * as Bootstrap from 'react-bootstrap'
import * as Icon from 'react-feather';
import Moment from "moment";
import { GoogleMap, Marker, useLoadScript, InfoWindow} from '@react-google-maps/api';
import useStateWithCallback from "use-state-with-callback";
const markers = [];

function Map(props) {
    const { domain, domainApi, apicode, admin, nameProvince } = props;
    const mapMarker=domain+admin.mapmarker
    const [, setMap] = useState(null)
    const [first, setFirst] = useState(true)
    const mapMarkerCurrent=domain+admin.mapmarkercurrent
    const currentPosition={lat:props.lat,lng:props.long}
    const [loadedSucursales,setLoadedSucursales] = useState(false)
    const [pages,setPages] = useState(null)
    const [localidades,setLocalidades] = useState(null)
    const [searchCode,setSearchCode] = useState(1)
    const [message,setMessage] = useState(1)
    const [currentProvincia,setCurrentProvincia] = useStateWithCallback(null,()=>{
        if(first){
            fetchData()
            setFirst(false)
        }
    })
    const currentMunicipio = null
    const currentLat = null
    const [page, setPage] = useStateWithCallback(1,()=>{
        if(first){
            fetchData()
            setFirst(false)
        }
    });

    const [showSchedule, setShowSchedule] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [, setMarkerMap] = useState({});
    const [center, setCenter] = useState({
        lat: parseFloat(props.lat),
        lng: parseFloat(props.long)
    });

    const [zoom, setZoom] = useState(12);
    const [infoOpen, setInfoOpen] = useState(false);
    const [currentpage, setCurrentpage] = useState(1);
    const [sucursales, setSucursales] = useState(null);
    const selected=props.currentProvincia
    const [selectedView, setSelectedView]=useState(true)

    // Load the Google maps scripts

    const handleSelectView = (eventKey) =>{
        let newselected=true
        if(selectedView){
            newselected=false
        }
        setSelectedView(newselected)
    }
    const handleClick = (id) => {
        var myClasses = document.querySelectorAll('.btndetallebtn')
        let i = 0
        let l = myClasses.length
        for (i; i < l; i++) {
            myClasses[i].style.display = 'inline-block';
        }
        setShowSchedule(id)
    }

    const fetchData = () => {
        window.scrollTo(0, 0)
        let formData = {page:page};
        if(currentProvincia !== null){
            formData['provincia']=currentProvincia
        }
        if(currentMunicipio !== null){
            formData['municipio']=currentMunicipio
        }
        if(nameProvince !== null){
            formData['nameprovince']=nameProvince
        }

        let header = new Headers({
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'multipart/form-data'
        });

        let url =domainApi+'sucursalesData/'+apicode+'?'+new URLSearchParams(formData)
        fetch(url,{
            method:'GET',
            mode: 'cors',
            header: header}).then((response)=>{
            return response.json();
        }).then((json)=>{
            setLoadedSucursales(true)
            setSucursales(json.sucursales.items)
            setLocalidades(json.sucursales.localidades)
            setPages(json.sucursales.pages)
            setSearchCode(parseInt(json.sucursales.searchcode))
            setMessage(json.sucursales.message)
        })

    }


    const changePage = (value) => {
        setFirst(true)
        setCurrentpage(value)
        setPage(value)
    }

    const filterBy = () => {
        let select=document.getElementById('provinciasselector')
        let selectitem=select.options[select.selectedIndex]
        setSelectedPlace(false)
        select=selectitem.value
        setFirst(true)
        setCurrentProvincia(select)
        setZoom(9)
        let getlat=selectitem.getAttribute('data-lat')
        let getlng=selectitem.getAttribute('data-lng')
        setCenter({lat:parseFloat(getlat),lng:parseFloat(getlng)})
    }


    const markerLoadHandler = (marker, place) => {
        markers.push(marker)
        return setMarkerMap(prevState => {
            return { ...prevState, [place.id]: marker };
        });
    };


    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const selecting =(place) => {
        // Remember which place was clicked
        setSelectedPlace(place);
        // Required so clicking a 2nd marker works as expected

        setInfoOpen(true);
        setCenter({lat:parseFloat(place.latitude),lng:parseFloat(place.longitude)})
        // If you want to zoom in a little on marker click
        if (zoom < 13) {
            setZoom(13);
        }
    }

    const markerClickHandler = (event, place) => {
        setInfoOpen(false)
        setSelectedPlace(false)
        selecting(place)
        setSelectedView(true)
        setCenter({lat:parseFloat(place.latitude),lng:parseFloat(place.longitude)})
    };

    let countingall=0
    let day= Moment().format('E');
    let hour= Moment().format('Hmm');
    hour=parseFloat(hour)
    day=parseFloat(day)

    let pagination =[]
    if(currentpage > 1){
        pagination.push(<Pagination.Item key={'paginationfirst'} onClick={()=>changePage(1)}>««</Pagination.Item>)
        pagination.push(<Pagination.Item key={'paginationanterior'} onClick={()=>changePage(currentpage-1)}>«</Pagination.Item>)
    }
    for(let i=1; i<= pages; i++){
        if(i > currentpage-3 && i < currentpage+3){
            if(pages > 1){
                if(i === currentpage){
                    pagination.push(<Pagination.Item key={'pagination'+i} active>{i}</Pagination.Item>)
                }else{
                    pagination.push(<Pagination.Item key={'pagination'+i} onClick={()=>changePage(i)}>{i}</Pagination.Item>)
                }
            }
        }
    }
    if(pages > 1) {
        pagination.push(<Pagination.Item key={'paginationsiguiente'}

                                         onClick={() => changePage(currentpage + 1)}>»</Pagination.Item>)
        pagination.push(<Pagination.Item key={'paginationultimo'}
                                         onClick={() => changePage(pages)}>»»</Pagination.Item>)
    }

    const containerStyle = {
        height: "90%",
        width: "100%"
    };
    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: admin.googleAPI
    })
    let textshow = 'Mostrar'
    if(selectedView){
        textshow = 'Ocultar'
    }
    return isLoaded && loadedSucursales ? <Row className={'vh-100 mx-2'}>
        <Col xs={12}>
            <div className={'p-2 text-left'}>
                <Row>
                    <Col xs={12} md={3}><Subtitle bg={'d-inline-block mt-2 mb-0'} value={'Oficinas'}/></Col>
                    <Col>
                        <select defaultValue={selected} id={'provinciasselector'} onChange={filterBy} className={'form-control'}>
                            <option value={''}>-Todas las Provincias-</option>
                            {localidades !== null ? localidades.map(function (item) {
                                return (
                                    <option data-lat={item.latitude} data-lng={item.longitude} value={item.id}  key={'provincias'+item.id} >{item.name}</option>
                                )
                            }) : null}
                        </select>
                    </Col>
                    <Col >
                        <Bootstrap.Nav className={'float-right'} variant={'pills'} onSelect={handleSelectView}>
                            <Bootstrap.Nav.Item>
                                <Bootstrap.Nav.Link className={selectedView ? "active mr-2":"mr-2"} eventKey={"1"}>{textshow} Mapa</Bootstrap.Nav.Link>
                            </Bootstrap.Nav.Item>
                        </Bootstrap.Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'p-0 text-left'}>
                            {searchCode === 0 ? <div className={'alert alert-info my-2'}><p className={'mb-0'}>{message}</p></div> : null}
                            <div className={'overflow-auto sucursalesspace'}>
                                <div className={'row'}>
                                    {sucursales !== null ? sucursales.map(function(item){
                                        countingall++
                                        let schedulelunviernes=item.schedulelunviernes
                                        let schedulesabados=item.schedulesabados
                                        let scheduledomingos=item.scheduledomingos
                                        let open = false
                                        let days
                                        let until
                                        if(day === 0){
                                            day = 7
                                        }

                                        if(day >=1 && day <= 5){
                                            days=schedulelunviernes
                                        }
                                        if(day === 6){
                                            days=schedulesabados
                                        }

                                        if(day === 7){
                                            days=scheduledomingos
                                        }

                                        if(days.length > 0){
                                            days[0].schedule_days_schedules.map(function (itemd){
                                                let maxhour=itemd.maxhour+"00"
                                                maxhour=parseFloat(maxhour)
                                                let minhour=itemd.minhour+"00"
                                                minhour=parseFloat(minhour)
                                                if(itemd.daysid === day && hour >= minhour && hour <= maxhour){
                                                    open = true
                                                }
                                                until = Moment(maxhour,"hh").format('h a')
                                                return null
                                            })
                                        }

                                        let styleval
                                        if(showSchedule === item.id){
                                            styleval={display:'block'}
                                        }else{
                                            styleval={display:'none'}
                                        }
                                        return(
                                            <div key={'temsucursales'+item.id} className={'col-12 col-md-12'}>
                                                <Card className={'mt-3 mb-2 no-border no-shadow rounded-0 border-0 p-0'} key={'sucursal'+item.id}>
                                                    <Card.Body className={'text-left px-0 py-2'}>
                                                        <Card.Body>
                                                            <button className="p-0 border-0 text-uppercase bg-white text-left" onClick={event => markerClickHandler(event, item)}>{item.name}</button>
                                                            <div className={'services-logo d-flex justify-content-between align-items-center'}>
                                                            {item.pagatodo && <div><img alt={'Paga Todo'} src={domain+'/img/logotipo_header.png'}/></div>}
                                                            {item.vimenpaq && <div><img alt={'Vimenpaq'} src={domain+'/img/Vimenpaq_logo.png'}/></div>}
                                                            </div>
                                                            <div><span><Icon.MapPin size={14}/> {item.address}, {item.localidade.name}</span><br/>
                                                                {item.telefono !== null && <span><a href={'tel:'+item.telefono}><Icon.Phone size={14}/> {item.telefono}</a></span>}
                                                            </div>
                                                            <small>{item.tipo_negocio.name} • {open === true ? <span><span onClick={()=>{handleClick(item.id)}} className={'text-success'}>Abierto</span> • Hasta las {until}</span> : <span className={' mt-3 text-danger'}>Cerrado</span>}
                                                            </small>
                                                            <br/>
                                                            
                                                            <button onClick={event => markerClickHandler(event, item)}
                                                                    className={'btn btn-small bgyellow text-white'}>Ver ubicación</button>
                                                            <a target={'_blank'} rel="noopener noreferrer" className={'btn btn-outline-warning text-small ml-2'} href={'https://www.google.com/maps/search/?api=1&query='+item.latitude+','+item.longitude}>Ir al Mapa</a>
                                                            <div className={'scheduledetail'+item.id} style={styleval}>
                                                                <table className={'table mt-3 table-sm table-striped w-100'}>
                                                                    <tbody>
                                                                    {
                                                                        schedulelunviernes.length > 0 ?
                                                                            <tr>
                                                                                <td>Lunes - Viernes </td>
                                                                                <td>
                                                                                    {schedulelunviernes[0].schedule_days_schedules.map(itemday=>{
                                                                                        if(itemday.daysid === 1){
                                                                                            return(<p key={'itemhoursl'+itemday.id} className={'m-0'}>{Moment(itemday.minhour,"hh").format('h a')} - {Moment(itemday.maxhour,"hh").format('h a')}</p>)
                                                                                        }else{
                                                                                            return null
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            : null
                                                                    }
                                                                    {schedulesabados.length > 0 ? <tr><td>Sábados </td><td>
                                                                        {schedulelunviernes[0].schedule_days_schedules.map(itemday=>{
                                                                            if(itemday.daysid === 6){
                                                                                return(<p key={'itemhourss'+itemday.id} className={'m-0'}>{Moment(itemday.minhour,"hh").format('h a')} - {Moment(itemday.maxhour,"hh").format('h a')}</p>)
                                                                            }else{
                                                                                return null
                                                                            }
                                                                        })
                                                                        }
                                                                    </td></tr>: null}
                                                                    {scheduledomingos.length > 0 ? <tr><td>Domingos </td><td>
                                                                        {schedulelunviernes[0].schedule_days_schedules.map(itemday=>{
                                                                            if(itemday.daysid === 7){
                                                                                return(<p key={'itemhoursd'+itemday.id} className={'m-0'}>{Moment(itemday.minhour,"hh").format('h a')} - {Moment(itemday.maxhour,"hh").format('h a')}</p>)
                                                                            }else{
                                                                                return null
                                                                            }
                                                                        })
                                                                        }
                                                                    </td></tr>: null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Card.Body>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        )

                                    }) : null }
                                </div>
                            </div>
                            <Pagination className={'px-4 mt-3'}>{pagination}</Pagination>

                        </div>
                    </Col>
                    {selectedView ?
                        <Col xs={12} md={8} id={'mapspace'}>
                            <Fragment>
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >

                                    {currentPosition !== null ?
                                        <Marker
                                            onLoad={marker => markerLoadHandler(marker, {id:'Estas aqui.'})}
                                            onClick={event => markerClickHandler(event, {id:'Estas aqui.'})}
                                            position={currentPosition}
                                            icon={mapMarkerCurrent}
                                        /> : null}
                                    {sucursales !== null ? sucursales.map(place => {
                                        let latx=parseFloat(place.latitude)
                                        let longx=parseFloat(place.longitude)
                                        var myLatlng = new window.google.maps.LatLng(latx,longx)
                                        return (
                                            <Marker
                                                key={place.id}
                                                position={myLatlng}
                                                onLoad={marker => markerLoadHandler(marker, place)}
                                                onClick={event => markerClickHandler(event, place)}
                                                icon={mapMarker}
                                            />
                                        )
                                    }) : null }
                                    {infoOpen && selectedPlace ?
                                        <InfoWindow
                                            //anchor={mapMarker[selectedPlace.id]}
                                            position={{lat:(parseFloat(selectedPlace.latitude)+0.01300),lng:parseFloat(selectedPlace.longitude)}}
                                            onCloseClick={() => setInfoOpen(false)}>
                                            <div className={'text-left'}>
                                                <p className={'mb-0'}>{selectedPlace.name}</p>
                                                <p className={'mb-0'}>{selectedPlace.address}</p>
                                                <p>{selectedPlace.establecimiento}</p>
                                                {selectedPlace.todopago === true ? <img className="mr-2" width="80" alt={'Todopago'} src={props.domain+'/img/logotipo_header.png'}/> : null}
                                                {selectedPlace.vimenpaq === true ? <img width="80" alt={'Vimenpaq'} src={props.domain+'/img/Vimenpaq_logo.png'}/> : null}
                                                {selectedPlace.simpleyrapido === true ? <img width="80" alt={'Simple y Rápido'} src={props.domain+'/img/simple_rapido.png'}/> : null}
                                                {selectedPlace.simpleyrapido === true ? <p>{selectedPlace.limitaciones}</p> : null}
                                                {currentLat !== null && selectedPlace.address !== undefined?
                                                    <div className={'mt-3'}>
                                                        <a target={'_blank'} rel="noopener noreferrer" className={'btn ml-2  btn-outline-warning text-small'} href={'https://www.google.com/maps/search/?api=1&query='+selectedPlace.latitude+','+selectedPlace.longitude}>Ir al Mapa</a>
                                                    </div> : null}
                                                {selectedPlace.address === undefined? <p>Aqui estas.</p>: null}
                                            </div>
                                        </InfoWindow>
                                        : null}

                                </GoogleMap>
                            </Fragment>
                        </Col>
                        :null}
                </Row>

            </div>
        </Col>

    </Row> : <Loader/>
}


class Sucursales extends React.Component{
    constructor(props){
        super(props)
        this.state={
            contact:null,
            domainApi:props.global.domainApi,
            domain:props.global.domain,
            admin:props.main.get.main,
            domainpanel:props.global.domainpanel,
            apicode:props.global.api,
            lat:null,
            long:null,
            idLoadedGeo:false,
            nameProvince:null
        }

    }

    componentDidMount() {
        var getPosition = function (options) {
            return new Promise(function (resolve, reject) {
                navigator.geolocation.getCurrentPosition(resolve, reject, options);
            });
        }
        getPosition()
            .then((position) => {
                let lat
                let long
                if(position.coords !== undefined){
                    long=position.coords.longitude
                    lat=position.coords.latitude
                }else{
                    lat='18.4800295'
                    long='-70.0169207'
                }

                let url='https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+long+'&key='+this.state.admin.googleAPI

                fetch(url).then(json=>json.json()).then(result=>{
                    let itemre=result.results[0].address_components
                    let nameProvince = null
                    itemre.map(address=>{
                        let types=address.types
                        if(types.includes('administrative_area_level_1')){
                            nameProvince= address.long_name
                        }
                        return null
                    })
                    setTimeout(this.setState({
                        lat:lat,
                        long:long,
                        idLoadedGeo:true,
                        nameProvince:nameProvince
                    }),1000)
                    }
                )
            })
    }

    render(){
        let idLoadedGeo=this.state.idLoadedGeo;

        if(idLoadedGeo){
            return (<div className="bg-white border-bottom-yellow text-center vh-100">
                <Map
                    lat={this.state.lat}
                    long={this.state.long}
                    admin={this.state.admin}
                    clicked={this.state.clicked}
                    selected={this.state.selectedPlace}
                    domain={this.state.domain}
                    domainApi={this.state.domainApi}
                    apicode={this.state.apicode}
                    sucursales={this.state.sucursales}
                    nameProvince={this.state.nameProvince}
                />
            </div>)
        }else{
            return (<Loader/>)
        }

    }
}

export default Sucursales;
